<template>
	<div>
		<a-spin :spinning="loading">
			<div style="min-height: 800px;" class="bg-w flex ">
				<div class="serviceman-detail-left">
					<div class="serviceman-detail-left-header">
						<div class="flex alcenter">
							<img  :src="detail.face" class="serviceman-detail-face"/>
							<div class="ml15">
								<div class="flex alcenter ">
									<div class="ft16 ftw600 cl-main text-over4">{{detail.name}}</div>
									<div class="appointment-serviceman-item-tag-status-on text-over ml4" v-if="detail.status==1">{{detail.status_means}}</div>
									<div class="appointment-serviceman-item-tag-status-off text-over ml4" v-if="detail.status==-1">{{detail.status_means}}</div>
								</div>
								
								<div class="mt15 flex alcenter">
									<i class="iconfont iconcall_gray mr5 ft12 cl-notice"/>
									<div class="cl-info ft12 ftw400 text-over4">{{detail.mobile}}</div>
								</div>
								
								<div class="mt15 flex alcenter">
									<i class="iconfont icongongpai mr5 ft12 cl-notice"/>
									<div class="cl-info ft12 ftw400 text-over4">{{detail.job_number}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="serviceman-detail-left-content">
						<div class="serviceman-detail-left-content-item">
							<div class="flex space alcenter" style="height: 100%;">
								<div class="ft14 ftw400 cl-main">指派预约订单数</div>
								<div class="ft20 ftw600 cl-theme">{{detail.appointment_num}}</div>
							</div>
						</div>
						
					</div>
					
				</div>
			
				<div class="serviceman-detail-right">
					<a-layout>
						<a-layout-header class="layout-classes-header">
							<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
								<a-menu-item key="order">预约订单</a-menu-item>
							</a-menu>
						</a-layout-header>
						<a-layout-content class="layout-classes-content">
							<div v-if="selectCurrent == 'order'">
								<order-detail :serviceman_id="serviceman_id"></order-detail>
							</div>
						</a-layout-content>
					</a-layout>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import orderDetail from './components/ServicemanDetail/order.vue';
	export default{
		components:{
			orderDetail,
		},
		data(){
			return{
				loading:false,
				changTagsLoading:false,
				serviceman_id:0,
				selectCurrent: 'order',
				form:{
					tag:'',
				},
				detail:{
					face:'',
					name:'',
					mobile:'',
					working_collar:0,
					job_number:'',
					brief_introduction:'',
					status:1,
					status_means:'',
					engaged_num:0,
					projects_count:0,
					tags:[],
					appointment_num:0,
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.serviceman_id=0;
			} else {
				this.serviceman_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getServicemanDetail',{
					serviceman_id:this.serviceman_id
				}).then(res=>{
					this.detail=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
		}
	}
</script>

<style>
	.serviceman-detail-left{
		width: 400px;
		min-height: 800px;
		border-right: 1px solid #EBEDF5;
		line-height: 1;
	}
	.serviceman-detail-left-header{
		/* height: 128px; */
		padding: 24px;
		border-bottom: 1px solid #EBEDF5;
	}
	
	.serviceman-detail-left-header-action{
		width: 272px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 24px;
	}
	.serviceman-detail-face{
		width: 80px;
		height: 80px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
	}
	
	.serviceman-detail-left-content{
		padding: 24px;
	}
	
	.serviceman-detail-left-content-item{
		width: 100%;
		height: 72px;
		background: #F7F8FC;
		border-radius: 8px;
		padding: 20px;
		line-height: 1;
	}
	
	
	.serviceman-detail-right{
		width: 100%;
	}
	
	
	.appointment-serviceman-item-tag-status-on{
		border-radius: 2px;
		border: 1px solid #4772FF;
		padding: 2px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #4772FF;
	}
	
	.appointment-serviceman-item-tag-status-off{
		border-radius: 2px;
		border: 1px solid #A6AEC2;
		padding: 2px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #A6AEC2;
	}
	
	.appointment-serviceman-item-tag-year{
		background: rgba(242, 130, 40, 0.1);
		border-radius: 2px;
		border: 1px solid rgba(177, 117, 34, 0.5);
		padding: 2px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #B17522;
	}
	
	.serviceman-detail-tags-item{
		padding: 7px 12px;
		background: #F5F6FA;
		border-radius: 15px;
		margin-right: 8px;
		margin-bottom: 8px;
		line-height: 1;
	}
	
	
	
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
