<template>
	<div>
		<div class="pd30">
			<div class="">
				<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
					<a-radio-button :value="1">待确认</a-radio-button>
					<a-radio-button :value="3">待服务</a-radio-button>
					<a-radio-button :value="4">服务中</a-radio-button>
					<a-radio-button :value="8">已完成</a-radio-button>
					<a-radio-button :value="-1">已取消</a-radio-button>
				</a-radio-group>
			</div>
			
			<div class="mt30">
				<div class="wxb-table-gray">
					<a-table rowKey="appointment_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
						:data-source="datas" :loading="loading">
						
						<div class="flex alcenter center" slot="day" slot-scope="day,record">
							<div style="line-height: 1.2;">
								<div>{{day}}</div>
								<div class="cl-theme">{{record.start_time}}-{{record.end_time}}</div>
							</div>
						</div>
						
						<div class="flex alcenter center" slot="member" slot-scope="member,record">
							<img :src="member.face"  class="member-face" />
							<div class="ml10" style="text-align: left;">
								<div>{{member.nick_name}}｜{{member.mobile}}</div>
							</div>
						</div>
						
						<div class="flex alcenter center" slot="project_id" slot-scope="project_id,record">
							<img :src="record.project_cover_img"  class="projetc-cover-img" />
							<div class="ml10 " style="text-align: left;">
								<div class="text-over4">{{record.project_name}}</div>
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-notice">规格：</div>
									<div class="ft12 ftw400 cl-notice text-over4">{{record.project_sku_name}}</div>
								</div>
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-notice">价格：</div>
									<div class="ft12 ftw400 cl-notice text-over4">{{record.total_price}}</div>
								</div>
							</div>
						</div>
						
						<div class="flex alcenter center" slot="status" slot-scope="status,record">
							<div v-if="status==1 || status==4 || status==3">{{record.status_means}}</div>
							<div v-if="status==8 || status==-1" class="cl-notice">{{record.status_means}}</div>
						</div>
						
						<div class="flex alcenter center" slot="is_paid" slot-scope="is_paid,record">
							{{is_paid==1 ? '已支付'+record.need_pay : '未支付'}}
						</div>
				
						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="showDetailAct(record)">
												<i class="iconfont ft14 iconsee"></i>
												<span class="ml10">查看详情</span>
											</a>
										</a-menu-item>
										<a-menu-item v-if="record.status==1">
											<a class="menu-act" href="javascript:;" @click="sureAppointAct(record)">
												<i class="iconfont ft14 iconorder_operation_confirm"></i>
												<span class="ml10">确认预约</span>
											</a>
										</a-menu-item>
										<a-menu-item v-if="record.status==3">
											<a class="menu-act" href="javascript:;" @click="startServiceAct(record)">
												<i class="iconfont ft14 iconorder_operation_startservice"></i>
												<span class="ml10">开始服务</span>
											</a>
										</a-menu-item>
										<a-menu-item v-if=" record.status==4">
											<a class="menu-act" href="javascript:;"  @click="completeAppointAct(record)">
												<i class="iconfont ft14 iconorder_operation_cancle"></i>
												<span class="ml10">完成服务</span>
											</a>
										</a-menu-item>
										<a-menu-item v-if="record.status==1 || record.status==3">
											<a class="menu-act" href="javascript:;"  @click="cancelAppointAct(record)">
												<i class="iconfont ft14 iconorder_operation_cancle"></i>
												<span class="ml10">取消预约</span>
											</a>
										</a-menu-item>
										
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
			
			<div v-if="cancelAppointVisible">
				<cancel-appoint :appointment_id="appointment_id" :visible="cancelAppointVisible" @cancel="cancelCancelAppoint" @ok="okCancelAppoint"></cancel-appoint>
			</div>
			<div v-if="distributionServicemanVisible">
				<distribution-serviceman :appointment_id="appointment_id" :visible="distributionServicemanVisible" @cancel="cancelDistributionServiceman"
				@ok="okDistributionServiceman"></distribution-serviceman>
			</div>
			
			<div v-if="completeAppointVisible">
				<complete-appoint :order="order" :visible="completeAppointVisible" @cancel="cancelCompleteAppoint" @ok="okCompleteAppoint"></complete-appoint>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	import cancelAppoint from '../order/modal/detail/modal/cancelAppoint.vue';
	import completeAppoint from '../order/modal/completeAppoint.vue';
	import distributionServiceman from '../order/modal/distributionServiceman.vue';
	export default{
		props:{
			serviceman_id:{
				type:Number,
				default:0,
			}
		},
		mixins:[listMixin],
		components:{
			cancelAppoint,
			distributionServiceman,
			completeAppoint,
		},
		data(){
			return{
				loading:false,
				search:{
					status:1,
				},
				cancelAppointVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
				order:{},
				appointment_id:0,
				columns: [
					{title: '预约ID',dataIndex: 'appointment_id',align: 'center'},
					{title: '预约时间',dataIndex: 'day',align: 'center',scopedSlots: {customRender: 'day'}},
					{title: '用户',dataIndex: 'member',align: 'center',width:250,scopedSlots: {customRender: 'member'}},
					{title: '预约项目',dataIndex: 'project_id',align: 'center',width:300,scopedSlots: {customRender: 'project_id'}},
					{title: '下单时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '支付状态',dataIndex: 'is_paid',align: 'center',scopedSlots: {customRender: 'is_paid'}},
					{title: '状态',dataIndex: 'status',align: 'center',scopedSlots: {customRender: 'status'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas:[],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getServicemanAppointmentList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					serviceman_id:this.serviceman_id,
					status:this.search.status,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			sureAppointAct(record){
				if(record.serviceman_id!=0){
					this.$confirm({
						title:'确认这个预约吗？',
						okText:"确定",
						okType:"danger",
						cancelText:"取消",
						onOk : ()=>{
							return new Promise((resolve,reject)=>{
								this.$http.api('admin/sureAppointment',{
									appointment_id:record.appointment_id,
								}).then(res=>{
									this.$message.success('确认成功');
									this.getLists();
								}).catch(res=>{
									console.log(res);
								}).finally(()=>{
									resolve();
								})
							})
						}
					})
				}else{
					this.distributionServicemanAct(record);
				}
			},
			
			distributionServicemanAct(record){
				this.appointment_id=record.appointment_id;
				this.distributionServicemanVisible=true;
			},
			cancelDistributionServiceman(){
				this.distributionServicemanVisible=false;
			},
			okDistributionServiceman(){
				this.distributionServicemanVisible=false;
				this.getLists();
			},
			
			startServiceAct(record){
				this.$confirm({
					title:'确认开始服务了吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/startService',{
								appointment_id:record.appointment_id,
							}).then(res=>{
								this.$message.success('操作成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			//完成服务
			completeAppointAct(record){
				this.order=record,
				this.completeAppointVisible=true;
			},
			cancelCompleteAppoint(){
				this.completeAppointVisible=false;
			},
			okCompleteAppoint(){
				this.completeAppointVisible=false;
				this.getLists();
			},
			
			//查看订单详情
			showDetailAct(record){
				this.$router.push('/appointment/orderDetail?id='+record.appointment_id)
			},
			
			//取消预约
			cancelAppointAct(record){
				this.appointment_id=record.appointment_id;
				this.cancelAppointVisible=true;
			},
			cancelCancelAppoint(){
				this.cancelAppointVisible=false;
			},
			okCancelAppoint(){
				this.cancelAppointVisible=false;
				this.getLists();
			},
			
			changeStatus(){
				this.pagination.current = 1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-cover-img{
		width: 80px;
		height: 60px;
	}
	
	.member-face{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
</style>
